import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const Content = styled.div`
  width: 90%;
  max-width: 48rem;
  margin: 48px auto;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Header bgColor="TEAL" />
      <Content>
        <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
      </Content>
      <Footer bgColor="BLACK" />
    </Container>
  </Layout>
)

export default NotFoundPage
